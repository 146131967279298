<template>
	<div class="app-container">
		<div class="titlebox">
			<el-button size="medium" type="text" v-show="!isModal" @click="$router.go(-1)">返回</el-button>

			<div class="title">
				组织架构图
				<div class="btn_right">
					<el-popover placement="bottom" width="400" :offset="100" trigger="click">
						<el-tree
							:data="colums?.filter(({ key }) => !['orgName', 'orgFunction'].includes(key))"
							show-checkbox
							node-key="key"
							ref="checkTree"
							:props="defaultProps"
							:default-expand-all="true"
							:check-on-click-node="true"
							:expand-on-click-node="false"
							@check="treeCheck"
						>
						</el-tree>
						<el-button
							slot="reference"
							v-show="!query.isApproval"
							style="margin-right: 10px"
							size="mini"
							type="primary"
							icon="el-icon-setting"
						>
							设置
						</el-button>
					</el-popover>
					<el-button size="mini" v-if="query.isApproval && query.isEdit" type="primary" @click.stop="$router.go(-1)"> 组织列表 </el-button>
					<el-button size="mini" v-show="!query.isApproval" type="primary" icon="el-icon-download" @click.stop="downloadingMap">
						导出
					</el-button>
				</div>
			</div>
		</div>
		<div class="container" style="background-color: #fff">
			<div class="col-md-10 col-md-offset-1">
				<div class="text_center" id="box">
					<vue2-org-tree
						name="test"
						style="overflow: auto"
						:data="showData"
						:horizontal="horizontal"
						:collapsable="collapsable"
						:label-class-name="labelClassName"
						:render-content="renderContent"
						@on-expand="onExpand"
						@on-node-click="onNodeClick"
					/>
				</div>
			</div>
			<el-row style="text-align: center; padding: 20px; background-color: #fff" v-if="query.isApproval && this.isEdit">
				<el-button @click="$router.go(-1)">取消</el-button>
				<el-button type="primary" @click="submit">下一步</el-button>
			</el-row>
		</div>
		<!-- 新增编辑节点  -->
		<edit-dialog
			:dialogVisible.sync="showEditDialog"
			:operateType="operateType"
			:ruleForm="editForm"
			:selectedCityArr="selectedCity"
			:theTop="top"
			:isApproval="query.isApproval"
			@change="editChange"
		></edit-dialog>
		<!-- 移动弹框 -->
		<move-dialog
			:dialogVisible.sync="moveDialog"
			:organizeList="treeSelectData"
			:organzieId="organzieId"
			:lable="title"
			:isApproval="query.isApproval"
			@callBack="getMoveData"
			:orgList="tableDataList"
		></move-dialog>
	</div>
</template>
<script>
import editDialog from '../components/editDialog.vue';
import orgSet from '../../../staffSystem/applyApproval/orgApply/orgSet.vue';
import moveDialog from '../components/moveDialog.vue';
import { Loading } from 'element-ui';
import { printDiv, getUrlParams } from '@/utils/util';
// import { cloneDeep } from 'loadsh';
export default {
	// extends: orgSet,
	props: {
		isApproval: { type: Boolean },
		isView: { type: Boolean },
		isModal: { type: Boolean }
	},
	data() {
		return {
			data: [],
			horizontal: false,
			collapsable: true,
			expandAll: true,
			labelClassName: 'tree_content',
			operateType: '编辑组织',
			showEditDialog: false,
			editForm: {},
			top: '',
			selectedCity: [],
			moveDialog: false,
			treeSelectData: [],
			organzieId: '',
			title: '上级组织',
			defaultProps: {
				children: 'children',
				label: 'label',
				disabled: ({ key }) => key === 'orgName' || key === 'orgFunction'
			},
			showData: {},
			isEdit: false,
			colums: [],
			showCols: [
				// { key: 'orgPrinName', label: '组织负责人' },
				// { key: 'actualNumber', label: '当前实际人数(本层级)' },
				// { key: 'employeesNumberTotal', label: '当前实际人数(含下级)' }
			],
			query: {}
		};
	},
	components: { editDialog, moveDialog },
	watch: {
		data(val) {
			this.showData = val?.[0];
			// this.$refs.checkTree.setCheckedKeys(this.unfoldListCol([this.showData]));
		}
	},
	computed: {
		tableDataList() {
			return this.pavingData(this.data);
		}
		// checkedKeys() {
		// 	console.log(this.unfoldListCol([this.showData]), 'this.unfoldListCol([this.showData])');
		// 	return this.unfoldListCol([this.showData]);
		// }
	},
	created() {},
	mounted() {
		let params = getUrlParams(this.$route.query.params);

		if (params) {
			params = JSON.parse(params);
			this.query = params;
		}
		if (params?.isEdit) {
			this.isEdit = true;
		} else {
			this.isEdit = false;
		}
		if (this.isApproval) {
			this.query.isApproval = true;
			this.query.checkedKeys = ['orgLevel', 'cityName'];
		}
		if (this.isView) {
			this.isEdit = false;
		}
		this.getList();
		this.findOrgPermission();
		// this.data = params;
		// this.expandChange();
		// console.log(this.$route.query.params);
	},
	methods: {
		...orgSet.methods,
		// 权限限制
		findOrgPermission() {
			this.$axios.post('/v1/org/findOrgPermission', { request: {} }).then((res) => {
				if (res?.code) {
					return;
				}
				this.colums = res || [];
				let defaultKey = ['orgPrinName', 'actualNumber', 'actualNumberTotal'];

				if (this.query.checkedKeys) {
					defaultKey = this.query.checkedKeys;
				}
				this.showCols = res.filter(({ key }) => defaultKey.includes(key));
				this.$refs.checkTree.setCheckedKeys(defaultKey);
			});
		},
		getList(id) {
			const loading = Loading.service({ target: '.container' });

			let isAdmin = false;

			const org = this.$store.state.app.menusPermission?.find(({ component }) => component === 'OrganizationalManagement');

			if (org) {
				isAdmin = true;
			}

			if (this.query.isApproval) {
				this.$axios.post('/v1/org/tempList', { request: { staffType: id || '' } }).then((res) => {
					if (res[0]) {
						this.treeSelectData = [res[0]];
						this.$store.commit('SET_DEPT', res);
						this.data = [res[0]];
						this.expandChange();
					}
					loading.close();
				});
				return;
			}
			if (isAdmin) {
				this.$axios.post('/v1/org/list', { request: { staffType: id || '' } }).then((res) => {
					if (res[0]) {
						this.treeSelectData = [res[0]];
						this.$store.commit('SET_DEPT', res);
						this.data = [res[0]];
						this.expandChange();
					}
					loading.close();
				});
			} else {
				this.$axios.post('/v1/org/list/lower', { request: { orgId: this.$store.state.app.orgId || '' } }).then((res) => {
					if (res[0]) {
						this.treeSelectData = [res[0]];
						this.$store.commit('SET_DEPT', res);
						this.data = [res[0]];
						this.expandChange();
					}
					loading.close();
				});
			}
		},
		renderContent(h, data) {
			// return data.label;
			if (JSON.stringify(data) === '{}') {
				return;
			}
			const showContent = [
				this.showCols.map(({ key, label }) => {
					let text = label;

					if (['actualNumber', 'actualNumberTotal'].includes(key)) {
						text = label.replace('当前实际', '');
					}
					return (
						<div class="content_item">
							<span>{text}：</span>
							<span style="color:#333;">{data[key]}</span>
						</div>
					);
				})
			];

			if (!this.isEdit) {
				const content = (
					<div slot="reference" class="contentItem">
						<div class="content_name">{data.orgName}</div>
						{showContent}
					</div>
				);

				if (!data.orgFunction) {
					return content;
				}
				return (
					<el-popover placement="top-start" width="200" trigger="hover" content={data.orgFunction}>
						{content}
					</el-popover>
				);
			}
			return (
				<el-popover placement="top-start" width="200">
					<div slot="reference" class="contentItem">
						<div class="content_name">{data.orgName}</div>
						{showContent}
					</div>
					<div class="operate_row">
						<span onclick={() => this.handleAdd(data)}>新增</span>
						<span onclick={() => this.handleEdit(data)}>编辑</span>
						{data.orgPid !== '0' && <span onclick={() => this.handleMove(data)}>移动</span>}
						{data.orgPid !== '0' && (
							<span onclick={() => this.handleDelete(data)} style="color: #fe1c1c">
								删除
							</span>
						)}
					</div>
				</el-popover>
			);
		},

		onExpand(e, data) {
			if ('expand' in data) {
				data.expand = !data.expand;
				if (!data.expand && data.children) {
					this.collapse(data.children);
				}
			} else {
				this.$set(data, 'expand', true);
			}
			this.addEventContext();
		},
		onNodeClick() {
			// e.stopPropagation();
			// e.preventDefault();
			// if ('expand' in data) {
			// 	data.expand = !data.expand;
			// 	if (!data.expand && data.children) {
			// 		this.collapse(data.children);
			// 	}
			// } else {
			// 	this.$set(data, 'expand', true);
			// }
		},
		collapse(list) {
			const _this = this;

			list.forEach((child) => {
				if (child.expand) {
					child.expand = false;
				}
				// eslint-disable-next-line no-unused-expressions
				child.children && _this.collapse(child.children);
			});
		},
		expandChange() {
			this.toggleExpand(this.data, this.expandAll);
			this.addEventContext();
		},
		handleContext(e) {
			// e.stopPropagation();
			e.preventDefault();
			e.target.click();
		},
		addEventContext() {
			this.$nextTick(() => {
				const allEl = document.querySelectorAll('.contentItem');

				Array.from(allEl).forEach((el) => {
					el.removeEventListener('contextmenu', this.handleContext);
					el.addEventListener('contextmenu', this.handleContext);
				});
			});
		},
		toggleExpand(data, val) {
			const _this = this;

			if (Array.isArray(data)) {
				data.forEach((item) => {
					_this.$set(item, 'expand', val);
					if (item.children) {
						_this.toggleExpand(item.children, val);
					}
				});
			} else {
				this.$set(data, 'expand', val);
				if (data.children) {
					_this.toggleExpand(data.children, val);
				}
			}
		},
		beforeDestroy() {
			const allEl = document.querySelectorAll('.contentItem');

			Array.from(allEl).forEach((el) => {
				el.removeEventListener('contextmenu', this.handleContext);
			});
		},
		downloadingMap() {
			printDiv('org-tree', '组织架构图', true, false, () => {});
		},
		// 嵌套数据铺平
		unfoldListCol(cols) {
			if (cols && cols.length === 0) {
				return [];
			}
			let arr = [];

			cols.forEach((item) => {
				const p = item.children ? [item.id, ...this.unfoldListCol(item.children)] : item.id;

				arr = arr.concat(p);
			});
			return arr;
		},
		// 树状勾选
		treeCheck({ key }, { checkedKeys }) {
			if (checkedKeys?.length > 3) {
				this.$refs.checkTree.setCheckedKeys(checkedKeys.filter((k) => k !== key));
				this.$message.warning('不能超过三个');
				return;
			}
			this.showCols = this.colums.filter(({ key }) => checkedKeys.includes(key));

			this.addEventContext();
		},
		submit() {
			this.$router.push({
				path: '/staff/orgSubmit',
				query: {
					approvalId: this.query.approvalId,
					subType: this.query.subType
				}
			});
		}
	}
};
</script>
<style lang="scss">
.text_center {
	display: flex;
	justify-content: center;
}
.tree_content {
	padding: 0;
	text-align: left;
	.contentItem {
		padding-bottom: 10px;
		border: 1px solid #efefef;
		border-radius: 6px;
	}
}
.org-tree-node-label {
	white-space: nowrap;
}
#box .org-tree-node-label-inner {
	padding: 0;
	text-align: left;
	min-width: 160px;
	min-height: 100px;
}
#box .content_name {
	text-align: center;
	margin-bottom: 10px;
	background-color: #dcedff;
	color: #0066ff;
	font-size: 14px;
	height: 40px;
	line-height: 40px;
	border-radius: 6px 6px 0 0;
	padding: 0 10px;
}
#box .org-tree-node-label {
	/* border: 1px solid #0066ff; */
	border-radius: 8px;
}
#box .content_item {
	padding-left: 15px;
	line-height: 20px;
	font-size: 12px;
	color: #999999;
}
</style>
<style lang="scss">
.org-tree-container {
	display: inline-block;
	padding: 15px;
	background-color: #fff;
	height: calc(100vh - 110px);
}

.org-tree {
	background: #fff;
	// display: inline-block;
	display: table;
	text-align: center;
	padding: 20px;
	&:before,
	&:after {
		content: '';
		display: table;
	}

	&:after {
		clear: both;
	}
}

.org-tree-node,
.org-tree-node-children {
	position: relative;
	margin: 0;
	padding: 0;
	list-style-type: none;

	&:before,
	&:after {
		transition: all 0.35s;
	}
}
.org-tree-node-label {
	position: relative;
	display: inline-block;

	.org-tree-node-label-inner {
		padding: 10px 15px;
		text-align: center;
		border-radius: 3px;
		// box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
	}
}
.org-tree-node-btn {
	position: absolute;
	top: 100%;
	left: 50%;
	width: 20px;
	height: 20px;
	z-index: 10;
	margin-left: -11px;
	margin-top: 9px;
	background-color: #fff;
	border: 1px solid #1faaeb;
	border-radius: 50%;
	// box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
	cursor: pointer;
	transition: all 0.35s ease;

	&:hover {
		background-color: #e7e8e9;
		transform: scale(1.15);
	}

	&:before,
	&:after {
		content: '';
		position: absolute;
	}

	&:before {
		top: 50%;
		left: 4px;
		right: 4px;
		height: 0;
		border-top: 1px solid #1faaeb;
	}

	&:after {
		top: 4px;
		left: 50%;
		bottom: 4px;
		width: 0;
		border-left: 1px solid #1faaeb;
	}

	&.expanded:after {
		border: none;
	}
}
.org-tree-node {
	padding-top: 20px;
	display: table-cell;
	vertical-align: top;

	&.is-leaf,
	&.collapsed {
		padding-left: 10px;
		padding-right: 10px;
	}

	&:before,
	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 50%;
		height: 19px;
	}

	&:after {
		left: 50%;
		border-left: 1px solid #1faaeb;
	}

	&:not(:first-child):before,
	&:not(:last-child):after {
		border-top: 1px solid #1faaeb;
	}
}
.collapsable .org-tree-node.collapsed {
	padding-bottom: 30px;

	.org-tree-node-label:after {
		content: '';
		position: absolute;
		top: 100%;
		left: 0;
		width: 50%;
		height: 20px;
		border-right: 1px solid #1faaeb;
	}
}
.org-tree > .org-tree-node {
	padding-top: 0;

	&:after {
		border-left: 0;
	}
}
.org-tree-node-children {
	padding-top: 20px;
	display: table;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 50%;
		height: 20px;
		border-right: 1px solid #1faaeb;
		border-left: none;
	}

	&:after {
		content: '';
		display: table;
		clear: both;
	}
}

.horizontal {
	.org-tree-node {
		// display: flex;
		// flex-direction: row;
		// justify-content: flex-start;
		// align-items: center;
		display: table-cell;
		float: none;
		padding-top: 0;
		padding-left: 20px;

		&.is-leaf,
		&.collapsed {
			padding-top: 10px;
			padding-bottom: 10px;
		}

		&:before,
		&:after {
			width: 19px;
			height: 50%;
		}

		&:after {
			top: 50%;
			left: 0;
			border-left: 0;
		}

		&:only-child:before {
			top: 1px;
			border-bottom: 1px solid #1faaeb;
		}

		&:not(:first-child):before,
		&:not(:last-child):after {
			border-top: 0;
			border-left: 1px solid #1faaeb;
		}

		&:not(:only-child):after {
			border-top: 1px solid #1faaeb;
		}

		.org-tree-node-inner {
			display: table;
		}
	}

	.org-tree-node-label {
		display: table-cell;
		vertical-align: middle;
	}

	&.collapsable .org-tree-node.collapsed {
		padding-right: 30px;

		.org-tree-node-label:after {
			top: 0;
			left: 100%;
			width: 20px;
			height: 50%;
			border-right: 0;
			border-bottom: 1px solid #1faaeb;
		}
	}

	.org-tree-node-btn {
		top: 50%;
		left: 100%;
		margin-top: -11px;
		margin-left: 9px;
	}

	& > .org-tree-node:only-child:before {
		border-bottom: 0;
	}

	.org-tree-node-children {
		// display: flex;
		// flex-direction: column;
		// justify-content: center;
		// align-items: flex-start;
		display: table-cell;
		padding-top: 0;
		padding-left: 20px;

		&:before {
			top: 50%;
			left: 0;
			width: 20px;
			height: 0;
			border-left: 0;
			border-top: 1px solid #1faaeb;
		}

		&:after {
			display: none;
		}

		& > .org-tree-node {
			display: block;
		}
	}
}
.operate_row {
	color: #0066ff;
	font-size: 13px;
	text-align: center;
	span {
		margin-right: 8px;
		cursor: pointer;
	}
}
.title {
	.btn_right {
		float: right;
		display: block;
	}
}
</style>
