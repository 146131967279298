/*
 * @Author: 卢均锐morye
 * @Date: 2020-03-04 15:01:30
 * @LastEditTime: 2020-04-10 17:02:27
 */
export const Reject = { title: '不通过审批', value: 2, label: '不通过' };
export const Pass = { title: '通过', value: 1, label: '通过' };
export const Print = { title: '打印', value: 'print', label: '打印' };
export const Cancel = { title: '撤销审批', value: 3, label: '撤销' };
export const Details = { title: '查看详情', value: 4, label: '详情' };
export const Edit = { title: '编辑', value: 'edit', label: '编辑' };
export function makeComands(item, queryType, staffId) {
	let comands = [Details];
	queryType = Number(queryType);
	if (queryType !== 3) {
		if (item.approvalStatusName === '待审批' || item.approvalStatus === 0) {
			if (queryType !== 1) {
				comands.unshift(Pass);
			}
			if (staffId && item.createStaffId == staffId) {
				comands.unshift(Cancel);
			}
			if (Number(item.subType) !== 4 && queryType !== 1) {
				comands.push(Reject);
			}
		}
		if (item.approvalStatus == '8') {
			comands.unshift(Pass);
			comands.push(Reject);
		}
		if (queryType === 1 && [Reject.value, Cancel.value].includes(item.approvalStatus)) {
			comands.push(Edit);
		}
		if (item.approvalTypeName == '离职' && queryType == 2) {
			comands.push(Reject);
		}
	}
	// 组织架构等 去掉编辑
	if ([11, 12, 13, 14].includes(item?.subType)) {
		comands = comands.filter(({ value }) => value !== 'edit');
	}
	return comands;
}
export const Mode = {
	Edit: '修改申请',
	Add: '申请审核'
};
export function makeOperationTip(row, action) {
	return action === Pass ? row.approvalTypeName + '评价' : action.label + '原因';
}
