import { post } from "./api";

/**
 * 获取管理员列表
 * @param params
 * @returns {*}
 */
export const getAdminlist = (params) => post('/v1/user/admin/list', params);

/**
 * 新增管理员
 * @param params
 * @returns {*}
 */
export const addAdmin = (params) => post('/v1/user/admin/add', params);

/**
 * 获取管理员角色
 * @param params
 * @returns {*}
 */
export const getAdminRole = (params) => post('/v1/user/admin/role/list', params);

/**
 * 修改管理员信息
 * @param params
 * @returns {*}
 */
export const updateAdmin = (params) => post('/v1/user/admin/update', params);

/**
 * 删除管理员
 * @param params
 * @returns {*}
 */
export const deleteAdmin = (params) => post('/v1/user/admin/delete', params);

/**
 * 获取角色列表
 * @param params
 * @returns {*}
 */
export const getRolelist = (params) => post('/v1/user/role/list', params);

/**
 * 新增角色
 * @param params
 * @returns {*}
 */
export const addRole = (params) => post('/v1/user/role/add', params);

/**
 * 修改角色
 * @param params
 * @returns {*}
 */
export const updateRole = (params) => post('/v1/user/role/update', params);

/**
 * 删除角色
 * @param params
 * @returns {*}
 */
export const deleteRole = (params) => post('/v1/user/role/delete', params);

/**
 * 删除角色
 * @param params
 * @returns {*}
 */
export const updateAdminRole = (params) => post('/v1/user/admin/role/update', params);

/**
 * 获取所有权限列表
 * @param params
 * @returns {*}
 */
export const getAllPermission = (params) => post('/v1/user/permission/list', params);

/**
 * 当前用户权限列表
 * @param params
 * @returns {*}
 */
export const getUserpermission = (params) => post('/v1/user/permission', params);

/**
 * 角色权限列表
 * @param params
 * @returns {*}
 */
export const getRolepermission = (params) => post('/v1/user/role/permission/list', params);

/**
 * 角色权限修改
 * @param params
 * @returns {*}
 */
export const editRolepermission = (params) => post('/v1/user/role/permission/update', params);
/**
 * 获取部门人员
 * @param params
 * @returns {*}
 */
export const getStaffList = (params) => post('/v1/org/department/staff/list', params);
/**
 * 获取部门人员
 * @param params
 * @returns {*}
 */
export const getInfoByroleId = (params) => post('/v1/user/role/info', params);
/**
 * 用户-查询投递者列表
 * @param params
 * @returns {*}
 */
export const getContributors = (params) => post('/v1/approval/contributors', params);