<template>
	<app-layout class="separated-layout" :sbdx="true" v-loading="loading">
		<el-row slot="header" type="flex" class="judgeBoxs" justify="space-between;">
			<el-col :span="4" style="text-align: left; padding-left: 15px; font-size: 16px">
				<el-button size="medium" type="text" style="color: black" @click="$router.go(-1)">返回</el-button>
			</el-col>
			<el-col :span="16" style="text-align: center">
				<h4 style="line-height: 40px">{{ title }}</h4>
			</el-col>
			<el-col :span="4" style="text-align: right; padding-right: 10px">
				<el-button v-print="'#approvalDetailsTable'" style="transform: translateY(3px)" type="primary" size="small" icon="el-icon-printer"
					>打印</el-button
				>
			</el-col>
		</el-row>
		<div>
			<div class="tables-wrapper" id="approvalDetailsTable">
				<table v-for="(table, key) in tables" class="table__print" :key="key">
					<thead style="position: relative">
						<h4 class="table-title">{{ table.title }}</h4>
						<div v-if="table.orgRecords?.length" class="changeView">
							<el-button type="text" icon="el-icon-view" @click="dialogOrgVisible = true">调整预览</el-button>
						</div>
						<!-- 入职逻辑 -->
						<div v-if="$route.query.subType === '8' && key === 'applyValueList'" class="changeView">
							<el-button type="text" @click="$router.push({ path: `/user_info?id=${$route.query.staffId}&index=11` })"
								>更多详细信息</el-button
							>
						</div>
					</thead>
					<tbody v-if="table.orgRecords?.length">
						<tr class="table-col_4" v-for="(record, inx) in table.orgRecords" :key="record.id">
							<td class="property-value" style="text-align: left; padding-left: 10px">
								{{ `${inx + 1}、${record.remark}` }}
							</td>
						</tr>
					</tbody>
					<tbody v-else-if="table.col === 4">
						<tr v-for="(row, i) in table.data" class="table-col_4" :key="i">
							<template v-for="(cell, ci) in row">
								<td class="property-name" :key="'name_' + ci">{{ cell.approvalPropertyName }}</td>
								<td class="property-value" :key="'value_' + ci">
									<div class="table-cell__content">
										<template v-if="cell.approvalPropertyType !== 7">
											{{ cell.approvalPropertyValue }}
										</template>
										<template v-else>
											<div
												class="docContainer"
												style="width: 100%"
												v-for="(file, index) in makeFileList(cell.approvalPropertyValue)"
												:key="index"
											>
												<div class="docContent">
													<img src="/static/images/fj.png" style="width: 40px; height: 40px; margin-right: 5px" />
													<div style="width: auto">
														<!-- <el-tooltip :content="file.name"
                                        placement="top"> -->
														<span class="fileName">{{ file.name }}</span>
														<!-- </el-tooltip> -->
														<div style="text-align: left; transform: translateY(5px)">
															<el-button
																v-show="isImageFileName(file.url)"
																type="text"
																size="small"
																class="media-print__hidden"
																@click="picPreview(file.url)"
																>预览</el-button
															>
															<a
																v-if="!isImageFileName(file.url)"
																class="media-print__hidden"
																style="color: #409eff; font-size: 12px; margin-right: 5px"
																target="_blank"
																:href="getHrefByFile(file)"
																>预览</a
															>
															<el-button
																type="text"
																size="small"
																class="media-print__hidden"
																@click="downLoadFile(file.url)"
																>下载</el-button
															>
														</div>
													</div>
												</div>
											</div>
											<!-- <a :key="i"
                         :href="file.url||file.httpUrl"
                         style="display:block;"
                          class="link-text fj-icon"
                         v-for="(file, i) in makeFileList(cell.approvalPropertyValue)">
                        {{file.name||file.fileName}}
                      </a> -->
										</template>
									</div>
								</td>
							</template>
						</tr>
					</tbody>
					<tbody v-else-if="table.col === 2 && table.title == '审批记录'">
						<tr :key="i" class="table-col_2" v-for="(row, i) in table.data">
							<td class="property-name">{{ row.approvalStatusName }}</td>
							<td class="property-value">
								<p style="line-height: 30px; font-weight: bold">
									{{ row.userName }}
									{{ row.approvalStatus == 0 || row.approvalStatus == 6 || row.approvalStatus == 7 ? '' : row.time }}
								</p>
								<p style="line-height: 30px" v-if="row.approvalStatusName !== '发起'">审批意见: {{ row.approvalSuggestion }}</p>
								<div class="docContainer" style="width: 100%" v-for="(file, index) in makeFileList(row.fileUrl)" :key="index">
									<div class="docContent">
										<img src="/static/images/fj.png" style="width: 40px; height: 40px; margin: 0 5px 0 -5px" />
										<div style="width: auto">
											<!-- <el-tooltip :content="file.name"
                                    placement="top"> -->
											<span class="fileName">{{ file.name }}</span>
											<!-- </el-tooltip> -->
											<div style="text-align: left">
												<el-button
													v-show="isImageFileName(file.url)"
													type="text"
													size="small"
													class="media-print__hidden"
													@click="picPreview(file.url)"
													>预览</el-button
												>
												<a
													v-show="!isImageFileName(file.url)"
													class="media-print__hidden"
													style="color: #409eff; font-size: 12px; margin-right: 5px"
													target="_blank"
													:href="getHrefByFile(file)"
													>预览</a
												>
												<el-button type="text" size="small" class="media-print__hidden" @click="downLoadFile(file)"
													>下载</el-button
												>
											</div>
										</div>
									</div>
								</div>
							</td>
						</tr>
					</tbody>
					<tbody v-else-if="table.col === 2 && table.title == '抄送记录'">
						<tr class="table-col_2">
							<td class="property-name">抄送人</td>
							<td class="property-value">
								<span v-for="(row, i) in table.data" :key="i" v-show="table.data.length > 0"
									>{{ row.userName }}{{ i == table.data.length - 1 ? '' : ',' }}</span
								>
								<span v-if="table.data.length <= 0">无记录</span>
							</td>
						</tr>
					</tbody>
				</table>
				<div style="display: flex; justify-content: flex-end; margin-top: 30px">
					<P style="width: 200px">签字:</P>
					<p style="width: 200px">日期:</p>
				</div>
			</div>
		</div>

		<el-dialog :visible.sync="dialogVisible" width="30%" :title="dialogAction.title">
			<template v-if="dialogAction === Pass">
				<h3 style="text-align: center; font-size: 16px; font-weight: bold">你确定要通过{{ title }}</h3>
			</template>
			<template>
				<el-form>
					<el-form-item :label="makeOperationTip({ approvalTypeName: $route.query.approvalTypeName }, dialogAction)">
						<el-input type="textarea" :maxlength="100" v-model="approvalParams.approvalDescription" :rows="4"></el-input>
					</el-form-item>
					<el-form-item label="类型" v-if="showType == 'pass'">
						<el-radio-group v-model="radioType">
							<el-radio label="1">本人</el-radio>
							<el-radio label="2">转交他人</el-radio>
							<el-radio label="3">插入上级</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="转交人" v-if="showType == 'pass' && radioType != 1">
						<el-select v-model="approvalParams.staffId" filterable :filter-method="_getStaff" placeholder="请选择" size="small">
							<el-option v-for="item in userArr" :key="item.staffId" :value="item.staffId" :label="item.staffName"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="附件" v-if="(showType == 'pass' && radioType == 1) || showType == 'reject'">
						<el-upload
							ref="upload"
							class="upload-demo"
							action="#"
							element-loading-text="正在上传......"
							show-file-list
							multiple
							:limit="5"
							v-loading="contrachLoading"
							:file-list="fileList"
							:on-exceed="handleExceed"
							:on-change="handleChange"
							:on-remove="handleRemove"
							:auto-upload="false"
							accept=".JPG,.JPEG,.DOC,.DOCX,.XLS,.XLSX,.PDF,.ZIP,.RAR"
						>
							<el-button size="small" type="primary">点击上传</el-button>
							<div slot="tip" class="el-upload__tip" style="padding-left: 40px; line-height: 20px">
								文件格式为JPG/JPEG/DOC/DOCX/XLS/XLSX/PDF/ZIP/RAR，大小在5MB内，数量在5个之内
							</div>
						</el-upload>
					</el-form-item>
				</el-form>
			</template>
			<div slot="footer">
				<el-button @click="cancelDialog">取消</el-button>
				<el-button @click="submitApproval" type="primary" :loading="subLoading">确认</el-button>
			</div>
		</el-dialog>
		<el-dialog :visible.sync="dialogVisible2">
			<img width="100%" :src="dialogImageUrl" alt="" />
		</el-dialog>
		<div style="text-align: center" slot="footer">
			<template v-if="$route.path === '/staff/approval_details'">
				<template v-for="(comand, i) in makeComands({ approvalStatus, subType })">
					<el-button type="primary" @click="operate(comand)" :key="i">{{ comand.label }}</el-button>
				</template>
			</template>
			<el-button type="primary" v-if="$route.params.type === 'print'" v-print="'#approvalDetailsTable'">{{ Print.label }}</el-button>
		</div>
		<!-- 组织架构图 -->
		<el-dialog :visible.sync="dialogOrgVisible" fullscreen> <organizeMap isApproval isView isModal /> </el-dialog>
	</app-layout>
</template>
<script>
import { getApprovalDetails, operateApproval } from '../../api/staffSystem';
import { Reject, Pass, Print, Cancel, Details, makeOperationTip } from './const';
import { isImageFileName } from '../../utils/util.js';
import { getStaffList } from '@/api/system';
import { approvalChange } from '@/api/staffSystem';
import organizeMap from '../organizingpersonnel/organizing/organizational/organizeMap.vue';
export default {
	components: { organizeMap },
	data() {
		return {
			Reject,
			Pass,
			Print,
			Cancel,
			Details,
			dialogVisible: false,
			dialogVisible2: false,
			dialogOrgVisible: false,
			loading: false,
			subLoading: false,
			title: '',
			staffId: '',
			createStaffId: '',
			dialogAction: '',
			dialogImageUrl: '',
			tables: {
				basicValueList: {
					title: '基本信息',
					data: [],
					col: 4
				},
				applyValueList: {
					title: '申请详情',
					data: [],
					col: 4
				},
				approvalProcessList: {
					title: '审批记录',
					data: [],
					col: 2
				},
				ccList: {
					title: '抄送记录',
					data: [],
					col: 2
				}
			},
			approvalStatus: '',
			subType: '',
			approvalParams: {
				applyId: '',
				approvalStatus: '',
				approvalDescription: ''
			},
			radioType: '1', // 审批通过类型
			fileList: [],
			uploadVal: [],
			contrachLoading: false,
			userArr: [],
			showType: '',
			previewFormat: ['xlsx', 'doc', 'docx', 'pptx'] // 预览格式
		};
	},
	mounted() {
		this.approvalParams.applyId = this.$route.query.id;
		this.getApprovalDetails();
		this._getStaff();
	},
	methods: {
		makeOperationTip,
		isImageFileName,
		makeComands(item) {
			const comands = [];

			const queryType = Number(this.$route.query.queryType);

			if (queryType !== 3) {
				if (item.approvalStatusName === '待审批' || item.approvalStatus === 0 || item.approvalStatus == 8) {
					if (queryType !== 1) {
						comands.unshift(Pass);
					}
					if (this.createStaffId == this.$store.state.app.staffId) {
						comands.unshift(Cancel);
					}
					// Number(item.subType) !== 4 &&
					if (Number(queryType) !== 1) {
						comands.push(Reject);
					}
				}
			}
			return comands;
		},
		makeFileList(json) {
			try {
				const fileList = JSON.parse(json);

				return fileList;
			} catch (err) {
				return [];
			}
		},
		submitApproval() {
			if (
				this.$store.state.app.range.staffIds?.includes(this.staffId) &&
				(this.subType == '3' || this.subType == '6') &&
				this.approvalParams.approvalStatus == '1'
			) {
				this.$message.warning('当前员工人岗匹配进行中，请结束后审核');
				return false;
			}
			if (
				this.$store.state.app.range.performanceEmployeesIds?.includes(this.staffId) &&
				(this.subType == '3' || this.subType == '6') &&
				this.approvalParams.approvalStatus == '1'
			) {
				this.$message.warning('当前员工绩效考核进行中，请结束后审核');
				return false;
			}
			if (this.radioType == 1) {
				const _obj = { ...this.approvalParams };

				_obj.fileUrl = JSON.stringify(this.uploadVal);
				this.subLoading = true;
				operateApproval(_obj).then((res) => {
					this.subLoading = false;
					if (res._responseStatusCode === 0) {
						this.$message({
							type: 'success',
							message: '处理成功!'
						});
						this.$router.back();
						this.dialogVisible = false;
					}
				});
			} else {
				if (this.approvalParams.staffId == '') {
					this.$message.error('请选择转交人');
					return false;
				}
				const _obj = {
					applyId: this.approvalParams.applyId,
					type: this.radioType == 2 ? 1 : 2,
					staffId: this.approvalParams.staffId
				};

				this.subLoading = true;
				approvalChange(_obj).then((res) => {
					this.subLoading = false;
					if (res.code == 0) {
						this.approvalParams.staffId = '';
						this.$message({ type: 'success', message: '处理成功!' });
						this.$router.back();
						this.dialogVisible = false;
					}
				});
			}
		},
		cancelDialog() {
			this.dialogVisible = false;
		},
		openDialog(action) {
			this.dialogAction = action;
			this.approvalParams.approvalDescription = '';
			this.dialogVisible = true;
		},
		operate(command) {
			this.showType = '';
			if (command.value == 1) {
				// 通过
				this.showType = 'pass';
			} else if (command.value == 2) {
				// 不通过
				this.showType = 'reject';
			}
			this.approvalParams.approvalStatus = command.value;
			this.openDialog(command);
		},
		getApprovalDetails() {
			this.loading = true;
			getApprovalDetails(this.$route.query).then((res) => {
				this.loading = false;
				if (res._responseStatusCode === 0) {
					this.title = res.title;
					this.staffId = res.staffId;
					this.createStaffId = res.createStaffId;
					this.approvalStatus = res.approvalStatus;
					this.subType = res.subType;
					// eslint-disable-next-line guard-for-in
					for (const key in this.tables) {
						const table = this.tables[key];

						if (table.col === 4) {
							if (res[key].length % 2 !== 0) {
								res[key].push({});
							}
							// eslint-disable-next-line space-in-parens
							for (; res[key].length > 0; ) {
								this.tables[key].data.push(res[key].splice(0, 2));
							}
							if (key === 'applyValueList' && !res[key]?.length) {
								this.tables[key].orgRecords = res?.orgRecords || [];
							}
						} else {
							table.data = res[key];
						}
					}
				}
			});
		},
		downLoadFile(file) {
			const url = `${location.origin}/v1/common/downLoad?exportparam=${encodeURIComponent(
				JSON.stringify({ fileName: file.name, bucketName: file.bucketName, key: file.key, httpUrl: file.httpUrl })
			)}`;

			window.open(url);
		},
		getHrefByFile(file) {
			const format = file.url?.split('.')?.slice(-1)?.[0];

			if (this.previewFormat.includes(format.toLocaleLowerCase())) {
				return `https://view.officeapps.live.com/op/view.aspx?src=${file.url}`;
			}
			return file.url;
		},
		picPreview(url) {
			this.dialogImageUrl = url;
			this.dialogVisible2 = true;
		},
		handleExceed(files, filelist) {
			if (files.length > 5 || filelist.length === 5 || files.length + filelist.length > 5) {
				this.$message({
					message: '上传文件数量不能超过五个',
					type: 'warning'
				});
			}
		},
		// 文件上传
		handleChange(file) {
			const isLt50M = file.size / 1024 / 1024 < 5;

			if (!isLt50M) {
				this.$alert('大小不得超过5M');
				return false;
			}
			const formData = new FormData();

			formData.append('imgFile', file.raw);
			const type = 'multipart/form-data;boundary=--------------------56423498738365';

			this.contrachLoading = true;
			this.$axios.post('/v1/common/upload/file', formData, { headers: { 'Content-Type': type } }).then((res) => {
				this.uploadVal.push({ name: res.fileName, size: '', url: res.httpUrl, bucketName: res.bucketName, key: res.key });
				this.contrachLoading = false;
			});
		},
		handleRemove(file) {
			const _list = [];

			for (const key in this.uploadVal) {
				if (this.uploadVal[key].name != file.name) {
					_list.push({ name: this.uploadVal[key].name, detail: this.uploadVal[key].detail });
				}
			}
			this.uploadVal = _list;
		},
		_getStaff(val) {
			const _obj = {
				pageNo: 1,
				pageSize: 100,
				keyWork: val || ''
			};

			getStaffList({ request: _obj }).then((res) => {
				this.userArr = res.list;
			});
		}
	}
};
</script>
<style lang="scss">
@import '../../styles/print-table.scss';
@media print {
	#approvalDetailsTable {
		background-color: #fff;
		height: 100%;
		padding: 0 !important;
		.changeView {
			display: none;
		}
		.table__print {
			width: 100%;
		}
	}
}
.separated-layout {
	background-color: #f6f7fb;
	.app-list-header {
		background-color: #fff;
	}
	.app-list-center {
		box-sizing: border-box;
		margin: 20px 20px 0;
	}
	// .app-list-footer {
	// }
}
.tables-wrapper {
	padding: 30px;
	background-color: #fff;
	table {
		font-size: 16px;
	}
}
.tables-wrapper table thead {
	font-size: 18px;
	color: #2b2b2b;
	padding-bottom: 27px;
	line-height: 50px;
	height: 50px;
	border-collapse: collapse;
	border-color: #c5cdda;
}
.tables-wrapper {
	.table-title {
		border-collapse: collapse;
		line-height: 50px;
		width: 100px;
		height: 50px;
		padding-bottom: 27px;
	}
	.changeView {
		position: absolute;
		right: 0;
		top: 0;
		font-size: 14px;
		color: #409eff;
	}
	.table__print {
		width: 50%;
		margin: 0 auto;
	}
}
.tables-wrapper .property-name {
	width: 20%;
	background: #f9faff;
}
.tables-wrapper .table-col_4 .property-value {
	width: 30%;
	overflow-wrap: anywhere;
	line-height: 1.5em;
}
.tables-wrapper .table-col_2 .property-value {
	text-align: left;
	padding-left: 20px;
	width: 80%;
}
.docContainer {
	.docContent {
		display: flex;
		align-items: center;
		// margin-left: 10px;
		min-width: 120px;
	}
	.fileName {
		// display: block;
		font-size: 13px;
		text-align: left;
		transform: translateY(5px);
		display: inline-block;
		// width: 300px;
		// overflow: hidden;
		// text-overflow: ellipsis;
		// white-space: nowrap;
	}
}
.judgeBoxs {
	background: #fff;
	padding: 7px 15px;
	margin: 0 14px 0 7px;
}
</style>
